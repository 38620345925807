<template>
  <div class="container" v-if="service==='transaction'">
    <div id='loader' v-if="is_loading"></div>
    <div class="support-history" v-else>
      <h3>{{ !_.isEmpty(dataDetails) ? dataDetails.page_title : '' }}</h3>
      <input type="hidden" id="page_no" value="2">
      <div class="justify-between">
        <h4>{{ !_.isEmpty(dataDetails) ? dataDetails.date_text : '' }}</h4>
        <input type="date" v-model="givenDate" value="" class="form-control date-box">
      </div>
      <div class="justify-between service-filter">
        <h4>{{ !_.isEmpty(dataDetails) ? dataDetails.service_text : '' }}</h4>
        <select id="service" class="form-control">
          <option value="">Select</option>
          <option @click="moService=service.value" v-for="service in dataDetails.mo_services" :value="service.value">
            {{ service.text }}
          </option>
        </select>
      </div>
      <div class="justify-between">
        <a href="javascript:void(0)"
           @click.prevent="getDetailsData"
           class="btn search-btn">{{ !_.isEmpty(dataDetails) ? dataDetails.search_btn_text : '' }}</a>
      </div>
      <div class="transaction-history" v-if="dataDetails.transactions">
        <span style="color: orangered; text-align: center; display: block"
              v-if="(dataDetails.transactions.length===0) || dataDetails.transactions===''">Transaction Data not found</span>
        <table class="fold-table">
          <tbody v-for="(transaction,index) in dataDetails.transactions">
          <tr class="view" :class="transactionDetailsShow ?(index===index_no?'open':''):''"
              @click="toggleTransactionDetails(index)">
            <td v-if="transaction.requestStatus==='success'"><img :src="pictureBaseUrl+'/digi/images/success-icon.svg'"
                                                                  alt="">
            </td>
            <td v-else><img :src="pictureBaseUrl+'/digi/images/fail-icon.svg'" alt=""></td>
            <td>+{{ !_.isEmpty(transaction) ? transaction.MSISDN : '' }}
              <span>{{ !_.isEmpty(transaction) ? transaction.serviceName : '' }}</span></td>
            <td>{{ !_.isEmpty(transaction) ? transaction.amount : '' }}</td>
          </tr>
          <tr class="fold" :class="transactionDetailsShow ?'open':''" v-if="index===index_no">
            <td colspan="2">
              <div class="support-details-box">
                <ul>
                  <li>Status: <strong>{{ !_.isEmpty(transaction) ? transaction.requestStatus : '' }}</strong></li>
                  <li>Transaction Time: <strong>{{ !_.isEmpty(transaction) ? transaction.updateTime : '' }}</strong>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <a href="javascript:void(0);" v-if="(dataDetails.transactions.length!==0) || dataDetails.transactions!==''"
           class="btn next-page"
           data-next_page="2" @click="nextPageData">{{ !_.isEmpty(dataDetails) ? dataDetails.btn_text : '' }}</a>
      </div>
    </div>
  </div>
  <div class="container" v-else>
    <div id='loader' v-if="is_loading"></div>
    <div class="support-history" v-else>
      <h3 v-if="operatorId==='1'">{{
          !_.isEmpty(dataDetails) ? dataDetails.refund_balance_text : ''
        }} :<span>{{ !_.isEmpty(dataDetails) ? dataDetails.balance : '' }}</span></h3>
      <input type="hidden" id="page_no" value="">
      <div class="justify-between">
        <h4>{{ !_.isEmpty(dataDetails) ? dataDetails.recent_history_text : '' }}</h4>
        <input type="date" id="date_control" v-model="givenDate" value="" class="form-control date-box">
      </div>
      <!--      <div class="justify-between service-filter">-->
      <!--        <h4>Service</h4>-->
      <!--        <select id="service" class="form-control">-->

      <!--        </select>-->
      <!--      </div>-->
      <!--      <div class="justify-between">-->
      <!--        <a href="javascript:void(0);" class="btn search-btn"></a>-->
      <!--      </div>-->
      <div class="transaction-history" v-if="dataDetails.all_transaction">
        <span style="color: orangered; text-align: center; display: block"
              v-if="(dataDetails.all_transaction.length===0) || dataDetails.all_transaction===''">Transaction Data not found</span>
        <table class="fold-table">
          <tbody v-for="(data,index) in dataDetails.all_transaction">
          <tr class="view" @click="toggleTransactionDetails(index)">
            <td v-if="data.status==='Successful'"><img :src="pictureBaseUrl+'/digi/images/success-icon.svg'" alt=""></td>
            <td v-else><img :src="pictureBaseUrl+'/digi/images/fail-icon.svg'" alt=""></td>
            <td>{{ !_.isEmpty(dataDetails) ? data.msisdn : '' }} <span>{{
                !_.isEmpty(dataDetails) ? data.package : ''
              }}</span></td>
            <td>{{
                !_.isEmpty(dataDetails) ? data.Execution_Amount : ''
              }}{{ !_.isEmpty(dataDetails) ? data.Execution_Currency : '' }}
            </td>
          </tr>
          <tr class="fold" :class="transactionDetailsShow ?'open':''" v-if="index===index_no">
            <td colspan="2">
              <div class="support-details-box">
                <ul>
                  <li v-if="operatorId === '1'">{{ !_.isEmpty(dataDetails) ? dataDetails.price_text : '' }}
                    <strong>{{ !_.isEmpty(dataDetails) ? data.Deducted_Amount : '' }}</strong></li>
                  <li>{{ !_.isEmpty(dataDetails) ? dataDetails.status_text : '' }} <strong v-if="data.status==='Successful'"
                    class="active">{{ !_.isEmpty(dataDetails) ? dataDetails.success_text : '' }} </strong>
                    <strong v-else-if="data.Refund_Status==='Refunded'"
                      class="active">{{ !_.isEmpty(dataDetails) ? dataDetails.refund_status_text : '' }} </strong>
                    <strong v-else
                            class="active">{{ !_.isEmpty(dataDetails) ? dataDetails.unsuccess_text : '' }} </strong>
                  </li>
                  <li>{{ !_.isEmpty(dataDetails) ? dataDetails.transaction_id_text : '' }}
                    <strong>#{{ !_.isEmpty(dataDetails) ? data.Transaction_ID : '' }}</strong></li>
                  <li>{{ !_.isEmpty(dataDetails) ? dataDetails.date : '' }}
                    <strong>{{ !_.isEmpty(dataDetails) ? data.Last_Updated : '' }}</strong></li>
                </ul>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <a href="javascript:void(0);" class="btn next-page" @click="nextPageData"
           data-next_page="">{{ !_.isEmpty(dataDetails) ? dataDetails.btn_text : '' }}</a>
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios'

export default {
  name: 'userHistory',
  data () {
    return {
      apiUrl: 'content/transaction-history',
      dataDetails: {},
      languageId: sessionStorage.getItem('language') ?? '1',
      msisdn: localStorage.getItem('msisdn') ?? '',
      pageId: 1,
      is_loading: true,
      operatorId: localStorage.getItem('operatorId') ?? '',
      service: this.$route.params.service ?? sessionStorage.getItem('service'),
      pictureBaseUrl: process.env.VUE_APP_MAIN_URL,
      transactionDetailsShow: false,
      index_no: '',
      givenDate: '',
      moService: ''
    }
  },
  mounted () {
    this.init()
    console.log('service', this.service)
  },

  methods: {
    async init () {
      this.is_loading = true
      await this.getData().then(response => {
        this.dataDetails = response.data
        this.is_loading = false
      })
    },
    getData () {
      if (this.service === 'transaction') {
        this.apiUrl = 'content/mo-transaction-history'
      }
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl, {
            languageId: this.languageId,
            msisdn: this.msisdn,
            pageID: this.pageId,
            givenDate: this.givenDate,
            moService: this.moService,
            operatorId: this.operatorId
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    getDetailsData () {
      this.init()
    },
    nextPageData () {
      this.pageId = this.pageId + 1
      this.init()
    },
    toggleTransactionDetails (index) {
      this.transactionDetailsShow = !this.transactionDetailsShow
      this.index_no = index
    }
  }
}
</script>

<style scoped>

</style>
